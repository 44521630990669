var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-modal',{attrs:{"name":"sunpod-accept","variant":"confirmation","hideCloseButton":true,"disableBodyScroll":true,"escToClose":!_vm.isLoading,"clickToClose":!_vm.isLoading},on:{"closed":_vm.onClose}},[_c('section',{staticClass:"overflow-auto h-96"},[_c('section',{staticClass:"px-4 pt-4 text-2xl font-extrabold text-center"},[_vm._v(" "+_vm._s(_vm.$t('components.pods.sunpods.details.steps.vehicles.assignVehicle.headline'))+" ")]),_c('div',{staticClass:"px-5 pt-5"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.onConfirm())}}},[_c('div',{staticClass:"relative mb-1 "},[_c('AppInput',{staticClass:"px-2 py-1 search-area",attrs:{"type":"text","placeholder":_vm.$t('components.pods.sunpods.details.steps.vehicles.assignVehicle.placeHolder'),"isSearch":true},on:{"focus":_vm.focusUpdate,"blur":_vm.blurUpdate},model:{value:(_vm.globalSearch),callback:function ($$v) {_vm.globalSearch=$$v},expression:"globalSearch"}}),_c('div',{staticClass:"absolute right-0 left-0 top-auto z-10 py-1 bg-white rounded rounded-t-none border border-t-0 "},[(_vm.isLoadingVehicles && _vm.isShow)?[_c('p',{staticClass:"mx-1"},[_vm._v("Loading...")])]:_vm._e(),(
                  !_vm.isLoadingVehicles &&
                    _vm.globalSearchResults.length === 0 &&
                    _vm.isShow
                )?[_c('div',{staticClass:"flex justify-between items-center py-2 px-2 text-sm font-medium"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('components.pods.sunpods.details.steps.vehicles.assignVehicle.message')))]),_c('i',{staticClass:"block text-red-500 fas fa-exclamation-circle"})])]:_vm._e(),(
                  !_vm.isLoadingVehicles &&
                    _vm.globalSearchResults.length > 0 &&
                    _vm.isShow
                )?_c('div',{staticClass:"list-container"},_vm._l((_vm.globalSearchResults),function(result){return _c('div',{key:result.id,staticClass:"flex justify-between items-center py-2 px-2 text-sm cursor-pointer hover:bg-gray-100 ",on:{"click":function($event){return _vm.onSet(result)}}},[_c('div',{staticClass:"w-6/12"},[_c('span',{staticClass:"text-gray-700"},[_vm._v(_vm._s(result.name))]),_c('br'),_c('span',{staticClass:"text-xs text-gray-500"},[_vm._v(_vm._s(result.qr_code))])]),_c('div',{staticClass:"w-4/12"},[_c('span',{staticClass:"text-gray-400 text-sm"},[_vm._v(_vm._s(result.organization_vehicle_model.title || '--'))])]),_c('div',{staticClass:"w-2/12 flex items-center justify-end"},[_c('HeartbeatStatus',{attrs:{"status":result.lock.is_operational,"text-class":"ml-2 text-xs","is-available":result.is_available}})],1)])}),0):_vm._e()],2)],1),_c('button',{ref:"submitButtonRef",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.confirm'))+" ")])])]}}])})],1),_c('div',{staticClass:"absolute bottom-0 w-full"},[_c('section',{staticClass:"flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50 "},[_c('AppButton',{class:"w-1/2",attrs:{"disabled":_vm.isLoading,"variant":"secondary"},on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.cancel'))+" ")]),_c('AppButton',{class:"w-1/2",attrs:{"variant":"primary","isLoading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":function($event){return _vm.$refs.submitButtonRef.click()}}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.assign'))+" ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }